var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-regular py-3"},[_vm._v("OCR Cards")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return this$1.fetchNidOcrCards(); }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-reload")]),_vm._v(" Reload ")],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"search":_vm.filterText,"headers":_vm.nidOcrCardHeaders,"items":_vm.nidOcrCards,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"disable-sort":"","item-key":"requestId","id":"match-table","footer-props":_vm.footerProps},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function (item) { return _vm.$router.push(_vm.requestImageUrl(item)); }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: item.userId } }}},[_vm._v(" "+_vm._s(item.userId)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","outlined":"","to":_vm.requestImageUrl(item)}},[_vm._v(" More ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }