<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular py-3">OCR Cards</v-toolbar-title>
      <v-spacer />

      <v-btn outlined @click="() => this.fetchNidOcrCards()">
        <v-icon class="mr-1">mdi-reload</v-icon> Reload
      </v-btn>
    </v-toolbar>

    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :search="filterText"
          :headers="nidOcrCardHeaders"
          :items="nidOcrCards"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :server-items-length="totalItems"
          disable-sort
          item-key="requestId"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          style="cursor: pointer"
          @click:row="(item) => $router.push(requestImageUrl(item))"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.userId`]="{ item }">
            <v-btn outlined :to="{ name: 'user.view', params: { id: item.userId } }">
              {{ item.userId }}
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small outlined :to="requestImageUrl(item)"> More </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { NID_OCR_CARDS } from "@/constants/api";

export default {
  name: "NidOcrCardIndex",
  metaInfo: { title: "NID Card List" },
  components: {},
  data() {
    return {
      loading: false,
      filterText: null,
      nidOcrCardHeaders: [
        { text: "Requested At", value: "createdAt", sortable: true },
        { text: "Requested By", value: "userId", sortable: true },
        { text: "Nid", value: "nid", sortable: true },
        { text: "Name", value: "nameEn", sortable: true },
        // { text: "Type", value: "type", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
      nidOcrCards: [],
      roles: {},
      selectedNidOcrCard: null,
      action: null,
      page: 1,
      itemsPerPage: 15,
      totalItems: null,
      optionsLength: 6,
      footerProps: { "items-per-page-options": [15, 30, 60, 100] },
    };
  },
  mounted() {
    this.page = Number(this.$route.query.page) || 1;
    this.itemsPerPage = Number(this.$route.query.limit) || 15;
    this.fetchNidOcrCards();
    // let self = this;
    // window.onresize = function () {
    //   self.updateTable();
    // };
    // this.fetchNidOcrCards().then(() => {
    //   this.updateTable();
    // });
  },
  computed: {
    pagination() {
      return { page: this.page - 1, limit: this.itemsPerPage };
    },
  },
  watch: {
    pagination() {
      if (
        Number(this.$route.query.page) !== this.page ||
        Number(this.$route.query.limit) !== this.itemsPerPage
      ) {
        this.$router.push({ query: { page: this.page, limit: this.itemsPerPage } });
      }
    },
    $route({ name }) {
      if (name === "nid-ocr-card.index") {
        this.page = Number(this.$route.query.page) || 1;
        this.itemsPerPage = Number(this.$route.query.limit) || 15;
        this.fetchNidOcrCards();
      }
    },
  },
  methods: {
    fetchNidOcrCards() {
      this.loading = true;
      return this.$axios
        .get(NID_OCR_CARDS, {
          params: this.pagination,
        })
        .then((response) => {
          if (response.data.code === 0) {
            this.nidOcrCards = response.data.data.items;
            this.totalItems = response.data.data.totalItems;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestImageUrl(nidOcrCard) {
      return {
        name: "nid-ocr-card.single",
        params: {
          requestId: nidOcrCard.requestId,
        },
      };
    },
    // updateTable() {
    //   // ref: https://stackoverflow.com/questions/58612261/vuetify-data-table-how-to-have-an-automatic-number-of-rows-per-page-depending
    //   let element = document.getElementById("match-table");
    //   let tableHeight = element ? element.offsetHeight : 800;
    //   this.itemsPerPage = Math.ceil(tableHeight / 65);
    //   if (this.nidOcrCards.length < this.itemsPerPage) {
    //     this.itemsPerPage = this.nidOcrCards.length;
    //   }
    //   if (!this.footerProps["items-per-page-options"].includes(this.itemsPerPage)) {
    //     if (this.footerProps["items-per-page-options"].length === this.optionsLength) {
    //       this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
    //     } else {
    //       this.footerProps["items-per-page-options"].shift();
    //       this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
    //     }
    //   }
    // },
  },
};
</script>

<style scoped></style>
